<template>
  <c-box
    w="100%"
    max-width="1270px"
    mx="auto"
    :my="['0', '16px']"
    h="auto"
    :background-color="['#FFF', '#FFF']"
    :box-shadow="['none', '2px 2px 10px rgba(0, 0, 0, 0.15)']"
    :border-radius="['0px', '16px']"
    p="0"
    :min-height="['calc(100vh - 62px)', '74vh']"
    overflow="hidden"
  >
    <!-- DIETELA TERBUKTI -->
    <c-box
      :padding="['0 0 0 0', '16px 16px 0 16px']"
    >
      <transition
        appear
        @enter="enter"
      >
        <c-box
          v-if="!!header.data?.image"
          :padding="['20px 16px 16px 16px', '32px 83px']"
          display="flex"
          :flex-direction="['column', 'row']"
          :gap="['8px', '70px']"
          justify-content="center"
          align-items="center"
          background-image="linear-gradient(#CCE8E6 50%, transparent )"
        >
          <c-box
            v-if="!!header.data?.image"
            class="imageHeaderGsap"
            position="relative"
            width="100%"
            max-width="350px"
            :display="['none', 'block']"
          >
            <c-image
              :src="header.data?.image"
              width="100%"
              height="100%"
            />
          </c-box>
          <c-box
            width="100%"
            position="relative"
            class="titleHeaderGsap"
          >
            <BaseText
              v-if="header.data?.header"
              size-mobile="18px-2"
              size-desktop="36px-2"
              text-align="center"
              color="#005A64"
            >
              {{ header.data?.header || '-' }}
            </BaseText>

            <BaseDivider border-color="#005A64" />

            <c-box
              :margin-top="['14px', '30px']"
            >
              <c-box
                v-for="(dataHeader, dataHeaderIdx) in header.data?.descriptionItems || []"
                v-show="header.data?.descriptionItems?.length > 0"
                :key="dataHeaderIdx"
                class="barHeaderGsap"
                :margin-top="dataHeaderIdx === 0 ? '0' : ['18px', '16px']"
              >
                <BaseProgressBar
                  class="tessss"
                  margin-top="8px"
                  :value="dataHeader.value"
                  :text="dataHeader.item"
                />
              </c-box>
            </c-box>
            <c-flex
              v-if="!!header.data?.subheader"
              margin-top="45px"
              :box-shadow="['0px 4px 15px 0px #00000026', '0px 4px 20px 0px rgba(0, 0, 0, 0.15)']"
              background-color="white"
              border-radius="12px"
              :padding="['12px 10px', '10px 20px']"
              class="infoHeaderGsap"
            >
              <c-box
                class="imageHeaderGsap"
                :display="['block', 'none']"
                width="100%"
                max-width="120px"
                position="relative"
              >
                <c-image
                  v-if="header.data?.image"
                  top="-50%"
                  object-fit="contain"
                  position="absolute"
                  :src="header.data?.image"
                  height="130%"
                  width="100%"
                />
              </c-box>
              <c-box
                width="100%"
              >
                <BaseText
                  size-mobile="18px"
                  size-desktop="20px"
                  color="#008C81"
                >
                  {{ header.data?.subheader || '-' }}
                </BaseText>
                <c-box
                  :margin-top="['12px', '20px']"
                  :margin-bottom="['0', '20px']"
                  :font-size="['14px', '16px']"
                  v-html="header.data?.description"
                />
              </c-box>
            </c-flex>
          </c-box>
        </c-box>
      </transition>
    </c-box>

    <!-- TABEL PERBANDINGAN LAYANAN KONSULTASI -->

    <c-box
      v-if="programs.length > 0 && facilities.length > 0"
      width="100%"
      min-width="100%"
      pos="relative"
      :background-image="[`url(${bgProgramConsultationMobile})`, `url(${bgProgramConsultation})`]"
      :background-position="['top center', 'top center']"
      background-repeat="no-repeat"
      :background-size="['100%', 'cover']"
      min-height="500px"
    >
      <transition
        appear
        @enter="enter2"
      >
        <c-box
          width="100%"
          max-width="1100px"
          :pt="['0', '1rem']"
          pb="1rem"
          :px="['10px', '0']"
          mx="auto"
          class="tableGsap"
        >
          <c-box
            :display="['block', 'none']"
            pos="absolute"
            top="-20px"
            left="50%"
            transform="translate(-50%)"
            width="400px"
            height="200px"
            border-radius="100%"
            background-image="linear-gradient(360deg, rgba(244, 204, 70, 0.2) 0%, rgba(244, 204, 70, 0) 86.11%)"
            z-index="2"
          />
          <c-box
            pos="relative"
            :display="['flex']"
            :flex-direction="['column', 'row']"
            :gap="['0' ,'5px']"
            color="primary.500"
            :font-size="['14px', '20px']"
            :font-weight="[500, 700]"
            justify-content="center"
            align-items="center"
            margin-bottom="1rem"
            z-index="3"
          >
            <c-box as="span">
              Pilih Layanan Konsultasi
            </c-box>
            <c-box as="span">
              yang Paling Cocok untuk Kebutuhanmu
            </c-box>
          </c-box>

          <c-box
            pos="relative"
            :display="['none', 'table']"
            as="table"
            width="100%"
            class="table-consultation"
            z-index="3"
          >
            <c-box
              as="thead"
              color="#FFF"
              background-color="primary.400"
              font-weight="700"
              border-radius="8px"
            >
              <c-box
                v-chakra="{
                  '>:first-child': {
                    borderLeft: '1px solid #005A64',
                  },
                  '>:last-child': {
                    borderRight: '1px solid #005A64',
                  },
                  '>:not(:last-child):not(:first-child)': {
                    borderRight: '1px solid #005A64',
                    borderLeft: '1px solid #005A64',
                  },
                }"
                as="tr"
              >
                <c-box
                  as="th"
                  text-align="center"
                  font-size="24px"
                  py="1rem"
                >
                  Fasilitas Layanan
                </c-box>
                <c-box
                  v-for="item in programs"
                  :key="item.id"
                  as="th"
                  flex-direction="column"
                  justify="center"
                  py="1rem"
                  width="200px"
                >
                  <c-text
                    font-size="20px"
                    margin-bottom="8px"
                  >
                    {{ item?.programsService }}
                  </c-text>
                  <c-text
                    font-size="24px"
                  >
                    {{ formatCurrency(item?.price, 2) }}
                  </c-text>
                </c-box>
              </c-box>
            </c-box>
            <c-box
              v-chakra="{
                '> tr': {
                  borderLeft: '1px solid #C4C4C4',
                  borderRight: '1px solid #C4C4C4',
                },
              }"
              as="tbody"
              background-color="#FFF"
              color="#000"
              :font-size="['12px', '16px']"
            >
              <c-box
                v-for="(facility, i) in facilities"
                :key="`tr-${i}`"
                v-chakra="{
                  '>:not(:last-child):not(:first-child)': {
                    borderRight: '1px solid #C4C4C4',
                    borderLeft: '1px solid #C4C4C4',
                  },
                }"
                as="tr"
              >
                <c-box
                  v-for="k in (getTotalProgram + 1)"
                  :key="`td-${i}-${k}`"
                  as="td"
                  px="1rem"
                  :text-align="k === 1 ? 'left' : 'center'"
                >
                  <c-box
                    v-if="k === 1"
                    w="100%"
                    :pt="i === 0 ? '2rem' : '1rem'"
                    pb="1rem"
                    :px="k === 1 ? '12px' : '0'"
                    border-bottom="1px solid #C4C4C4"
                  >
                    {{ facility?.facility }}
                  </c-box>
                  <c-box
                    v-else
                    w="100%"
                    :pt="i === 0 ? '2rem' : '1rem'"
                    pb="1rem"
                    border-bottom="1px solid #C4C4C4"
                    display="flex"
                    justify-content="center"
                  >
                    <inline-svg
                      v-if="isCheckList(getFacilityValue(facility?.facility, programs[k-2]?.productFacilitiesComparation))"
                      :src="checkIcon(getFacilityValue(facility?.facility, programs[k-2]?.productFacilitiesComparation)) ? iconCircleCheck : iconCircleClose"
                      class="icon-list"
                      :fill="checkIcon(getFacilityValue(facility?.facility, programs[k-2]?.productFacilitiesComparation)) ? '#008C81' : '#D32737'"
                    />
                    <c-box
                      v-else
                    >
                      {{ getFacilityValue(facility?.facility, programs[k-2]?.productFacilitiesComparation) }}
                    </c-box>
                  </c-box>
                </c-box>
              </c-box>
              <c-box
                v-chakra="{
                  '>:not(:last-child):not(:first-child)': {
                    borderRight: '1px solid #C4C4C4',
                    borderLeft: '1px solid #C4C4C4',
                  },
                }"
                as="tr"
                border-bottom="1px solid #C4C4C4"
              >
                <c-box as="td" />
                <c-box
                  v-for="item in programs"
                  :key="`buy-${item?.id}`"
                  as="td"
                  px="1rem"
                  py="1rem"
                >
                  <c-button
                    v-chakra="{
                      ':hover': {
                        color:'primary.500',
                        background:'secondary.500',
                      },
                      ':active': {
                        color: 'white',
                        background: 'secondary.600',
                      },
                    }"
                    width="100%"
                    variant="solid"
                    color="primary.400"
                    background="secondary.400"
                    border-radius="60px"
                    height="50px"
                    @click.prevent="onClickBuy({
                      productId: item?.productId,
                      productServiceId: item?.id,
                    })"
                  >
                    Beli Sekarang
                  </c-button>
                </c-box>
              </c-box>
            </c-box>
          </c-box>

          <c-box
            pos="relative"
            :display="['block', 'none']"
            z-index="3"
            width="100%"
          >
            <c-grid
              v-chakra="{
                '> :not(:last-child):not(:first-child) > div': {
                  borderLeft:'1px solid #005A64',
                  borderRight:'1px solid #005A64',
                },
              }"
              w="100%"
              :template-columns="`repeat(${getTotalProgram}, 1fr)`"
              background-color="primary.400"
              color="#FFF"
              font-size="14px"
              font-weight="500"
              border-top-left-radius="8px"
              border-top-right-radius="8px"
              border="1px solid #C4C4C4"
            >
              <c-box
                v-for="(item) in programs"
                :key="item.programsService"
                w="100%"
                py="6px"
              >
                <c-flex
                  w="100%"
                  flex-direction="column"
                  align-items="center"
                >
                  <c-text mb="8px">
                    {{ item.programsService }}
                  </c-text>
                  <c-text
                    font-size="16px"
                  >
                    {{ formatCurrency(item.price, 2) }}
                  </c-text>
                </c-flex>
              </c-box>
            </c-grid>
            <c-box
              p="16px 8px 8px 8px"
              color="#000"
              font-size="12px"
              font-weight="400"
              background-color="#FFF"
              border-bottom-left-radius="8px"
              border-bottom-right-radius="8px"
              border-left="1px solid #C4C4C4"
              border-right="1px solid #C4C4C4"
              border-bottom="1px solid #C4C4C4"
            >
              <c-box
                v-for="(facility, index) in facilities"
                :key="`facility-${index}`"
                mb="8px"
              >
                <c-box
                  p="6px 12px"
                  background-color="primary.50"
                  text-align="center"
                >
                  {{ facility?.facility }}
                </c-box>
                <c-grid
                  v-chakra="{
                    '> :not(:last-child):not(:first-child)': {
                      borderLeft:'1px solid #C4C4C4',
                      borderRight:'1px solid #C4C4C4',
                    },
                  }"
                  w="100%"
                  template-columns="repeat(3, 1fr)"
                >
                  <c-flex
                    v-for="j in getTotalProgram"
                    :key="j"
                    w="100%"
                    py="6px"
                    px="6px"
                    flex-direction="column"
                    align-items="center"
                    text-align="center"
                  >
                    <inline-svg
                      v-if="isCheckList(getFacilityValue(facility?.facility, programs[j-1]?.productFacilitiesComparation))"
                      :src="checkIcon(getFacilityValue(facility?.facility, programs[j-1]?.productFacilitiesComparation)) ? iconCircleCheck : iconCircleClose"
                      class="icon-list"
                      :fill="checkIcon(getFacilityValue(facility?.facility, programs[j-1]?.productFacilitiesComparation)) ? '#008C81' : '#D32737'"
                    />
                    <c-box
                      v-else
                    >
                      {{ getFacilityValue(facility?.facility, programs[j-1]?.productFacilitiesComparation) }}
                    </c-box>
                  </c-flex>
                </c-grid>
              </c-box>

              <c-grid
                v-chakra="{
                  '> :not(:last-child):not(:first-child)': {
                    borderLeft:'1px solid #C4C4C4',
                    borderRight:'1px solid #C4C4C4',
                  },
                }"
                w="100%"
                :template-columns="`repeat(${getTotalProgram}, 1fr)`"
              >
                <c-box
                  v-for="item in programs"
                  :key="item.id"
                  p="6px 4px"
                >
                  <c-button
                    v-chakra="{
                      ':hover': {
                        color:'primary.500',
                        background:'secondary.500',
                      },
                      ':active': {
                        color: 'white',
                        background: 'secondary.600',
                      },
                    }"
                    width="100%"
                    variant="solid"
                    color="primary.400"
                    background="secondary.400"
                    border-radius="16px"
                    height="34px"
                    font-size="12px"
                    px="0"
                    @click.prevent="onClickBuy({
                      productId: item?.productId,
                      productServiceId: item?.id,
                    })"
                  >
                    Beli Sekarang
                  </c-button>
                </c-box>
              </c-grid>
            </c-box>
          </c-box>
        </c-box>
      </transition>

      <!-- Layanan Utama -->
      <TransitionObserver
        v-show="!!layananUtama?.data?.description"
        @on-intersect="enter3"
      >
        <c-box
          max-width="1100px"
          width="100%"
          padding="0 1rem"
          mx="auto"
          class="layananUtamaGsap"
        >
          <c-flex
            id="tes"
            :padding="['12px 30px', '20px']"
            :flex-direction="['column', 'row']"
            align-items="center"
            box-shadow="0px 4px 20px 0px rgba(0, 0, 0, 0.15)"
            border-radius="10px"
            border-top-left-radius="70px"
            border-bottom-right-radius="70px"
            gap="16px"
            background-color="rgba(255, 255, 255, 0.7)"
          >
            <c-flex
              align-items="center"
              flex-direction="column"
              gap="16px"
              :width="['100%', '525px']"
            >
              <BaseText
                size-mobile="16px-2"
                size-desktop="20px-2"
                text-align="center"
                color="#005A64"
              >
                {{ layananUtama?.data?.description }}
              </BaseText>
              <c-image
                v-if="layananUtama?.data?.image"
                :src="layananUtama?.data?.image"
                :width="['150px', '65%']"
              />
            </c-flex>
            <c-flex
              flex-direction="column"
              align-items="center"
              width="100%"
            >
              <c-flex
                :margin-top="['0', '16px']"
                white-space="nowrap"
                flex-wrap="wrap"
                :gap="['6px', '16px']"
                justify-content="center"
                :flex-direction="['column', 'row']"
                width="100%"
              >
                <c-flex
                  v-for="item in layananUtama?.data?.descriptionItems"
                  :key="item"
                  align-items="center"
                  justify-content="space-between"
                  gap="8px"
                  border-radius="1000px"
                  padding="10px 16px"
                  background-color="white"
                  box-shadow="0px 4px 20px 0px rgba(0, 0, 0, 0.15)"
                  :font-size="['12px', '16px']"
                  :width="['100%', 'unset']"
                  overflow="auto"
                >
                  <p>
                    {{ item }}
                  </p>
                  <c-box
                    :width="['18px', '20px']"
                    :height="['18px', '20px']"
                    :min-width="['18px', '20px']"
                    :min-height="['18px', '20px']"
                  >
                    <inline-svg
                      :src="require('@/assets/icons/icon-circle-check.svg')"
                      height="100%"
                      width="100%"
                      fill="#008C81"
                    />
                  </c-box>
                </c-flex>
              </c-flex>
              <BaseButton
                :margin-top="['12px', '30px']"
                border-radius="1000px"
                @click="$router.push(layananUtama?.data?.button?.url || '#')"
              >
                {{ layananUtama?.data?.button?.text || '-' }}
              </BaseButton>
            </c-flex>
          </c-flex>
        </c-box>
      </TransitionObserver>
      <c-box height="200px" />
    </c-box>

    <!--    asdasd  -->
    <c-box
      margin-top="-170px"
    >
      <TransitionObserver
        v-if="listTestimoni?.length > 0"
        @on-intersect="enter4"
      >
        <c-box
          class="apaKataMerekaGsap"
        >
          <c-box
            width="100%"
            display="flex"
            flex-direction="column"
            justify-content="center"
            :padding="['0', '0 16px']"
          >
            <BaseText
              size-mobile="16px"
              size-desktop="28px"
              margin-bottom="8px"
              :padding="['0 16px', '0']"
            >
              Apa Kata Mereka?
            </BaseText>

            <VueSlickCarousel
              v-if="listTestimoni.length > 0"
              v-chakra="{
                '.slick-slide': {
                  padding: '0 16px 8px 0',
                  '@media(max-width: 767px)': {
                    padding: '0 16px',
                  },
                },
                '.slick-dots': {
                  bottom: '-25px',
                  li: {
                    '@media(max-width: 767px)': {
                      margin: '0 3px',
                    },
                    button: {
                      '::before': {
                        fontSize: '8px',
                        color: '#D9D9D9',
                        opacity: '1',
                        '@media(max-width: 767px)': {
                          fontSize: '10px',
                        },
                      },
                    },
                    '&.slick-active': {
                      button: {
                        '::before': {
                          fontSize: '12px',
                          color: '#008C81',
                          opacity: '1',
                        },
                      },
                    },
                  },
                },
              }"
              v-bind="settings"
            >
              <c-box
                v-for="(item) in listTestimoni"
                :key="item.id"
              >
                <c-box
                  position="relative"
                  :width="['100%', '332px']"
                  :h="['auto', '305px']"
                  background-color="#008C81"
                  border-radius="12px"
                  padding="16px"
                  display="flex"
                  flex-direction="column"
                  gap="8px"
                  padding-bottom="52px"
                  margin-bottom="39px"
                >
                  <c-box
                    display="flex"
                    gap="16px"
                  >
                    <c-box
                      min-width="60px"
                      width="60px"
                      height="60px"
                    >
                      <c-image
                        v-if="item.image"
                        :src="item.image"
                        height="100%"
                        width="100%"
                        border-radius="100%"
                        object-fit="cover"
                        object-position="50% 20%"
                      />
                    </c-box>
                    <c-box
                      display="flex"
                      flex-direction="column"
                      justify-content="center"
                      width="100%"
                    >
                      <BaseText
                        size-mobile="14px-2"
                        size-desktop="16px"
                        color="white"
                      >
                        {{ item.name }} ({{ item.age }} Tahun)
                      </BaseText>
                      <BaseText
                        size-mobile="12px"
                        size-desktop="14px-2"
                        color="white"
                      >
                        {{ item.location }}
                      </BaseText>
                    </c-box>
                  </c-box>
                  <c-box
                    height="100%"
                  >
                    <BaseText
                      size-mobile="12px"
                      size-desktop="14px-2"
                      margin-bottom="auto"
                      text-align="justify"
                      color="white"
                    >
                      {{ item.description }}
                    </BaseText>
                  </c-box>
                  <c-box
                    :width="['190px','222px']"
                    :min-height="['64px', '67px']"
                    background-color="white"
                    position="absolute"
                    bottom="0"
                    left="50%"
                    border="1px solid #008C81"
                    border-radius="12px"
                    transform="translate(-50%, 50%)"
                    padding="5px"
                    display="flex"
                    flex-direction="column"
                    justify-content="center"
                    align-items="center"
                    gap="5px"
                  >
                    <BaseText
                      size-mobile="10px"
                      size-desktop="12px"
                    >
                      Program yang digunakan: {{ item.product.name }}
                    </BaseText>
                    <BaseButton
                      border-radius="1000px"
                      :right-svg-icon="require('@/assets/icons/icon-arrow-right.svg')"
                      right-svg-icon-color="white"
                      height="34px"
                      @click="goToProgramDetail(item)"
                    >
                      Lihat layanan
                    </BaseButton>
                  </c-box>
                </c-box>
              </c-box>
            </VueSlickCarousel>
          </c-box>
        </c-box>
      </TransitionObserver>
    </c-box>

    <!--    masalah lainnya  -->
    <TransitionObserver
      v-show="listOtherProblems?.data?.length > 0"
      @on-intersect="enter5"
    >
      <c-box
        margin-bottom="20px"
        class="masalahLainnyaGsap"
        :padding="['42px 0 0 0', '16px 8px 8px 8px']"
      >
        <BaseText
          size-mobile="16px"
          size-desktop="28px"
          text-align="center"
        >
          Masalah Lainnya
        </BaseText>
        <VueSlickCarousel
          v-if="listOtherProblems?.data?.length > 0"
          v-chakra="{
            '.slick-slide': {
              padding: '8px',
              '@media(max-width: 767px)': {
                padding: '8px 0 16px 16px',
              },
            },
            '.slick-dots': {
              bottom: '-25px',
              li: {
                '@media(max-width: 767px)': {
                  margin: '0 3px',
                },
                button: {
                  '::before': {
                    fontSize: '8px',
                    color: '#D9D9D9',
                    opacity: '1',
                    '@media(max-width: 767px)': {
                      fontSize: '10px',
                    },
                  },
                },
                '&.slick-active': {
                  button: {
                    '::before': {
                      fontSize: '12px',
                      color: '#008C81',
                      opacity: '1',
                    },
                  },
                },
              },
            },
          }"
          :class="{ '--swiping' : swiping === true }"
          v-bind="settings2"
          @swipe="setCarouselSwiping(true)"
          @mouseup.native="setCarouselSwiping(false)"
          @touchend.native="setCarouselSwiping(false)"
        >
          <c-box
            v-for="(item) in listOtherProblems.data"
            :key="item.id"
            v-chakra="{
              ':hover': {
                backgroundColor: '#f3f3f3'
              },
              ':active': {
                backgroundColor: '#eaeaea'
              }
            }"
            :max-width="['180px', '230px']"
            min-height="250px"
            as="div"
            background-color="white"
            :box-shadow="['2px 2px 10px rgba(0, 0, 0, 0.15)']"
            border-radius="16px"
            padding="16px"
            display="flex"
            flex-direction="column"
            gap="8px"
            justify-content="center"
            align-items="center"
            @click="goToProgramDetail(item)"
          >
            <BaseText
              size-mobile="18px"
              size-desktop="28px"
              :color="['#008C81']"
              text-align="center"
            >
              {{ item.title }}
            </BaseText>
            <c-image
              :src="item.image"
              min-height="80px"
              height="100%"
              :max-height="['80px', '100px']"
              margin="auto"
            />
            <BaseText
              size-mobile="14px-2"
              size-desktop="14px-2"
              margin-bottom="auto"
              text-align="center"
            >
              {{ item.description }}
            </BaseText>
          </c-box>
        </VueSlickCarousel>
      </c-box>
    </TransitionObserver>

    <!--  MODAL -->
    <ModalInfoDifferentProgram
      :is-open="isOpenModalDifferentProgram"
      @close="isOpenModalDifferentProgram = false"
      @handle-confirm="checkout({ productId: selectedItem })"
    />
  </c-box>
</template>

<script>
import iconCircleClose from '@/assets/icons/icon-circle-close.svg'
import iconCircleCheck from '@/assets/icons/icon-circle-check.svg'
import bgProgramConsultation from '@/assets/bg-program-consultation.png'
import bgProgramConsultationMobile from '@/assets/bg-program-consultation-mobile.png'
import { formatCurrency } from '@/utils/format-currency'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { CBox, CFlex, CImage, CText, CGrid, CButton } from '@chakra-ui/vue'
import BaseDivider from '@/components/elements/base-divider.vue'
import BaseText from '@/components/elements/base-text.vue'
import BaseProgressBar from '@/components/elements/base-progress-bar-2.vue'
import { reqProgramKonsultasi } from '@/requests/dietela-api/general/program-konsultasi'
import { dragscroll } from 'vue-dragscroll'
import BaseButton from '@/components/elements/base-button.vue'
import VueSlickCarousel from 'vue-slick-carousel'
// optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
// optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import TransitionObserver from '@/components/elements/TransitionObserver.vue'
import anime from 'animejs/lib/anime.es.js'
import ModalInfoDifferentProgram from '@/components/widgets/modal-info-different-program.vue'

class DragScrollClickFix {
  constructor() {
    this.DRAG_DELAY = 100 // This is the minimal delay to consider a click to be a drag, mostly usefull for touch devices
    this.timer = null
    this.dragging = false
  }

  onDragScrollStart() {
    this.timer = setTimeout(() => this.onTimer(), this.DRAG_DELAY)
  }

  onTimer() {
    this.timer = null
    this.dragging = true
  }

  onDragScrollEnd() {
    if (this.timer) {
      clearTimeout(this.timer)
      this.timer = null
    }
    setTimeout(() => this.dragging = false)
  }

  onClickCapture(e) {
    if (this.dragging) {
      this.dragging = false
      e.preventDefault()
      e.stopPropagation()
    }
  }
}

export default {
  name: 'ProgramConsultation',
  components: {
    ModalInfoDifferentProgram,
    TransitionObserver,
    BaseButton,
    BaseProgressBar,
    CBox,
    BaseText,
    CImage,
    BaseDivider,
    CFlex,
    CText,
    CGrid,
    CButton,
    VueSlickCarousel,
  },
  directives: {
    dragscroll,
  },
  data() {
    return {
      isSubmittingCheckOrder: false,
      isOpenModalDifferentProgram: false,
      selectedItem: '',
      iconCircleClose,
      iconCircleCheck,
      bgProgramConsultation,
      bgProgramConsultationMobile,
      facilities: [],
      programs: [],
      listTestimoni: [],
      listOtherProblems: [],
      header: {},
      layananUtama: {},
      dragScrollClickFix: new DragScrollClickFix(),
      isIntersectingLayananUtama: false,
      swiping: false,
      swipingReal: false,
      swipingTimeout: null,
      settings: {
        dots: false,
        arrows: false,
        autoplay: true,
        infinite: true,
        autoplaySpeed: 5000,
        variableWidth: true,
        slidesToScroll: 1,
        pauseOnHover: true,
        pauseOnFocus: true,
        pauseOnDotsHover: true,
        responsive: [
          {
            breakpoint: 768,
            settings: {
              dots: true,
              adaptiveHeight: true,
              variableWidth: false,
              slidesToShow: 1,
            },
          },
        ],
      },
      settings2: {
        dots: false,
        arrows: false,
        autoplay: true,
        infinite: true,
        autoplaySpeed: 4000,
        variableWidth: true,
        slidesToScroll: 1,
      },
    }
  },
  computed: {
    ...mapGetters({
      isAuthenticated: 'auth/isAuthenticated',
    }),
    getTotalProgram() {
      return this.programs.length
    },
  },
  watch: {
    swiping(state) {
      if (state === false) {
        clearTimeout(this.swipingTimeout)
        this.swipingTimeout = setTimeout(() => {
          this.swipingReal = false
        }, 0)
        return
      }
      this.swipingReal = true
    },
  },
  mounted() {
    this.init()
  },
  methods: {
    ...mapMutations({
      setSelectedCart: 'cart/setSelectedCart',
    }),
    ...mapActions({
      getTestimoni: 'general/getTestimoni',
      getProgramConsultation: 'general/getProgramConsultation',
    }),
    setCarouselSwiping(state){
      this.swiping = state
    },
    handleScrollTopIfSamePath(productId) {
      if (productId === this.$route.params.id) {
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        })
        return true
      }
      return false
    },
    goToProgramDetail(item) {
      if (this.swipingReal) return
      const isSamePath = this.handleScrollTopIfSamePath(item?.product?.slug || item?.productId)
      if (isSamePath) return

      this.$router.push({
        name: 'client.program.detail',
        params: { id: item?.product?.slug || item?.productId },
      })
    },
    formatCurrency,
    async init() {
      const header_ = await reqProgramKonsultasi.header(this.$store.getters.axios)
      this.header = header_?.data

      const table = await this.getProgramConsultation()
        .catch((err) => {
          this.$toast({
            title: 'Failed',
            description: err.data.message ?? 'Ops! Something when wrong.',
            status: 'error',
            duration: 5000,
            position: 'bottom-right',
            variant: 'subtle',
          })
        })
      this.programs = table?.data?.result
      this.facilities = table?.data?.facilities

      const layananUtama = await reqProgramKonsultasi.layananUtama(this.$store.getters.axios)
      this.layananUtama = layananUtama?.data

      const testimoni = await this.getTestimoni()
      this.listTestimoni = testimoni.data || []

      const otherProblems = await reqProgramKonsultasi.otherProblems(this.$store.getters.axios)
      this.listOtherProblems = otherProblems?.data || []
    },
    enter() {
      const tl = anime.timeline()

      tl.add({
        targets: '.imageHeaderGsap',
        translateY: [100, 0],
        opacity: [0, 1],
        duration: 500,
        delay: 200,
        easing: 'cubicBezier(0.25, 0.1, 0.25, 1)',
      })
      tl.add({
        targets: '.titleHeaderGsap',
        translateX: [-100, 0],
        opacity: [0, 1],
        duration: 500,
        delay: 200,
        easing: 'cubicBezier(0.25, 0.1, 0.25, 1)',
      })
      tl.add({
        targets: '.barHeaderGsap',
        translateX: [-100, 0],
        opacity: [0, 1],
        duration: 500,
        delay: 200,
        easing: 'cubicBezier(0.25, 0.1, 0.25, 1)',
      })
      tl.add({
        targets: '.tessss > div',
        width: function(el) {
          const toValue = el.getElementsByTagName('p')?.[1]?.innerHTML?.trim() || '0%'
          return ['0%', toValue]
        },
        duration: 500,
        delay: anime.stagger(100),
        easing: 'cubicBezier(0.25, 0.1, 0.25, 1)',
      })
      tl.add({
        targets: '.infoHeaderGsap',
        translateY: [100, 0],
        opacity: [0, 1],
        duration: 500,
        easing: 'cubicBezier(0.25, 0.1, 0.25, 1)',
      })
    },
    enter2() {
      anime({
        targets: '.tableGsap',
        translateY: [100, 0],
        opacity: [0, 1],
        duration: 500,
        easing: 'cubicBezier(0.25, 0.1, 0.25, 1)',
      })
    },
    enter3() {
      anime({
        targets: '.layananUtamaGsap',
        translateY: [100, 0],
        opacity: [0, 1],
        duration: 500,
        easing: 'cubicBezier(0.25, 0.1, 0.25, 1)',
      })
    },
    enter4() {
      anime({
        targets: '.apaKataMerekaGsap',
        translateY: [100, 0],
        opacity: [0, 1],
        duration: 500,
        easing: 'cubicBezier(0.25, 0.1, 0.25, 1)',
      })
    },
    enter5() {
      anime({
        targets: '.masalahLainnyaGsap',
        translateY: [100, 0],
        opacity: [0, 1],
        duration: 500,
        easing: 'cubicBezier(0.25, 0.1, 0.25, 1)',
      })
    },
    getFacilityValue(label, data) {
      return data?.find(it => it?.productFacilities?.facility === label)?.value
    },
    isCheckList(value) {
      if (value && value?.includes('[x]') || value?.includes('[v]')) {
        return 1
      }
      return 0
    },
    checkIcon(value) {
      if (value && value?.includes('[x]')) {
        return 0
      }
      return 1
    },
    checkout({ productServiceId }) {
      const selectedCart = {
        'quantity': 1,
        'productService': {
          'id': productServiceId,
        },
        '_isBuyNow': true,
      }
      this.setSelectedCart(selectedCart)
      this.$router.push({ name: 'client.cart.check-order' })
    },
    async onClickBuy({ productId, productServiceId }) {
      if (!this.isAuthenticated) {
        this.$router.push({
          name: 'client.buy.step-2',
          query: {
            product_id: productId,
            product_service_id: productServiceId,
            duration: 1,
          },
        })
        return
      }
      window.fbq('track', 'BuyNow')

      // this.isSubmittingCheckOrder = true
      // const showPopupInfo = await reqCompareProduct(
      //   this.$store.getters.axios,
      //   product_service_id,
      // ).then((res) => res.data?.data?.isPopUp).finally(() => {
      //   this.isSubmittingCheckOrder = false
      // })
      // if (showPopupInfo) {
      //   this.selectedItem = product_service_id
      //   this.isOpenModalDifferentProgram = true
      //   return
      // }

      this.checkout({ productServiceId })
    },
  },
}
</script>

<style scoped>
.table-consultation {
  table-layout: fixed;
  border-collapse: collapse;
  border-spacing: 1px;
  overflow: hidden;
  border-radius: 8px;
}
.icon-list {
  width: 17px;
  height: 17px;
  flex-shrink: 0;
}

@media (min-width: 768px) {
  .icon-list {
    width: 20px;
    height: 20px;
  }
}

::v-deep .slick-slider.--swiping {
  ::v-deep .slick-list:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
  }
}
</style>