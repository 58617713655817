var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Observer', {
    attrs: {
      "root-margin": _vm.rootMargin,
      "threshold": 0.5
    },
    on: {
      "on-change": _vm.onChange
    }
  }, [_c('transition', {
    attrs: {
      "appear": ""
    }
  }, [_c('c-box', {
    attrs: {
      "height": "100%",
      "opacity": _vm.isIntersecting ? 1 : 0
    }
  }, [_vm._t("default")], 2)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }