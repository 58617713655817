var render = function () {
  var _vm$header$data, _vm$header$data2, _vm$header$data3, _vm$header$data4, _vm$header$data5, _vm$header$data6, _vm$header$data8, _vm$header$data9, _vm$header$data10, _vm$header$data11, _vm$header$data12, _vm$layananUtama, _vm$layananUtama$data, _vm$layananUtama2, _vm$layananUtama2$dat, _vm$layananUtama3, _vm$layananUtama3$dat, _vm$layananUtama4, _vm$layananUtama4$dat, _vm$layananUtama5, _vm$layananUtama5$dat, _vm$layananUtama7, _vm$layananUtama7$dat, _vm$layananUtama7$dat2, _vm$listTestimoni, _vm$listOtherProblems, _vm$listOtherProblems2, _vm$listOtherProblems3, _vm$listOtherProblems4;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', {
    attrs: {
      "w": "100%",
      "max-width": "1270px",
      "mx": "auto",
      "my": ['0', '16px'],
      "h": "auto",
      "background-color": ['#FFF', '#FFF'],
      "box-shadow": ['none', '2px 2px 10px rgba(0, 0, 0, 0.15)'],
      "border-radius": ['0px', '16px'],
      "p": "0",
      "min-height": ['calc(100vh - 62px)', '74vh'],
      "overflow": "hidden"
    }
  }, [_c('c-box', {
    attrs: {
      "padding": ['0 0 0 0', '16px 16px 0 16px']
    }
  }, [_c('transition', {
    attrs: {
      "appear": ""
    },
    on: {
      "enter": _vm.enter
    }
  }, [!!((_vm$header$data = _vm.header.data) !== null && _vm$header$data !== void 0 && _vm$header$data.image) ? _c('c-box', {
    attrs: {
      "padding": ['20px 16px 16px 16px', '32px 83px'],
      "display": "flex",
      "flex-direction": ['column', 'row'],
      "gap": ['8px', '70px'],
      "justify-content": "center",
      "align-items": "center",
      "background-image": "linear-gradient(#CCE8E6 50%, transparent )"
    }
  }, [!!((_vm$header$data2 = _vm.header.data) !== null && _vm$header$data2 !== void 0 && _vm$header$data2.image) ? _c('c-box', {
    staticClass: "imageHeaderGsap",
    attrs: {
      "position": "relative",
      "width": "100%",
      "max-width": "350px",
      "display": ['none', 'block']
    }
  }, [_c('c-image', {
    attrs: {
      "src": (_vm$header$data3 = _vm.header.data) === null || _vm$header$data3 === void 0 ? void 0 : _vm$header$data3.image,
      "width": "100%",
      "height": "100%"
    }
  })], 1) : _vm._e(), _c('c-box', {
    staticClass: "titleHeaderGsap",
    attrs: {
      "width": "100%",
      "position": "relative"
    }
  }, [(_vm$header$data4 = _vm.header.data) !== null && _vm$header$data4 !== void 0 && _vm$header$data4.header ? _c('BaseText', {
    attrs: {
      "size-mobile": "18px-2",
      "size-desktop": "36px-2",
      "text-align": "center",
      "color": "#005A64"
    }
  }, [_vm._v(" " + _vm._s(((_vm$header$data5 = _vm.header.data) === null || _vm$header$data5 === void 0 ? void 0 : _vm$header$data5.header) || '-') + " ")]) : _vm._e(), _c('BaseDivider', {
    attrs: {
      "border-color": "#005A64"
    }
  }), _c('c-box', {
    attrs: {
      "margin-top": ['14px', '30px']
    }
  }, _vm._l(((_vm$header$data6 = _vm.header.data) === null || _vm$header$data6 === void 0 ? void 0 : _vm$header$data6.descriptionItems) || [], function (dataHeader, dataHeaderIdx) {
    var _vm$header$data7, _vm$header$data7$desc;

    return _c('c-box', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: ((_vm$header$data7 = _vm.header.data) === null || _vm$header$data7 === void 0 ? void 0 : (_vm$header$data7$desc = _vm$header$data7.descriptionItems) === null || _vm$header$data7$desc === void 0 ? void 0 : _vm$header$data7$desc.length) > 0,
        expression: "header.data?.descriptionItems?.length > 0"
      }],
      key: dataHeaderIdx,
      staticClass: "barHeaderGsap",
      attrs: {
        "margin-top": dataHeaderIdx === 0 ? '0' : ['18px', '16px']
      }
    }, [_c('BaseProgressBar', {
      staticClass: "tessss",
      attrs: {
        "margin-top": "8px",
        "value": dataHeader.value,
        "text": dataHeader.item
      }
    })], 1);
  }), 1), !!((_vm$header$data8 = _vm.header.data) !== null && _vm$header$data8 !== void 0 && _vm$header$data8.subheader) ? _c('c-flex', {
    staticClass: "infoHeaderGsap",
    attrs: {
      "margin-top": "45px",
      "box-shadow": ['0px 4px 15px 0px #00000026', '0px 4px 20px 0px rgba(0, 0, 0, 0.15)'],
      "background-color": "white",
      "border-radius": "12px",
      "padding": ['12px 10px', '10px 20px']
    }
  }, [_c('c-box', {
    staticClass: "imageHeaderGsap",
    attrs: {
      "display": ['block', 'none'],
      "width": "100%",
      "max-width": "120px",
      "position": "relative"
    }
  }, [(_vm$header$data9 = _vm.header.data) !== null && _vm$header$data9 !== void 0 && _vm$header$data9.image ? _c('c-image', {
    attrs: {
      "top": "-50%",
      "object-fit": "contain",
      "position": "absolute",
      "src": (_vm$header$data10 = _vm.header.data) === null || _vm$header$data10 === void 0 ? void 0 : _vm$header$data10.image,
      "height": "130%",
      "width": "100%"
    }
  }) : _vm._e()], 1), _c('c-box', {
    attrs: {
      "width": "100%"
    }
  }, [_c('BaseText', {
    attrs: {
      "size-mobile": "18px",
      "size-desktop": "20px",
      "color": "#008C81"
    }
  }, [_vm._v(" " + _vm._s(((_vm$header$data11 = _vm.header.data) === null || _vm$header$data11 === void 0 ? void 0 : _vm$header$data11.subheader) || '-') + " ")]), _c('c-box', {
    attrs: {
      "margin-top": ['12px', '20px'],
      "margin-bottom": ['0', '20px'],
      "font-size": ['14px', '16px']
    },
    domProps: {
      "innerHTML": _vm._s((_vm$header$data12 = _vm.header.data) === null || _vm$header$data12 === void 0 ? void 0 : _vm$header$data12.description)
    }
  })], 1)], 1) : _vm._e()], 1)], 1) : _vm._e()], 1)], 1), _vm.programs.length > 0 && _vm.facilities.length > 0 ? _c('c-box', {
    attrs: {
      "width": "100%",
      "min-width": "100%",
      "pos": "relative",
      "background-image": ["url(".concat(_vm.bgProgramConsultationMobile, ")"), "url(".concat(_vm.bgProgramConsultation, ")")],
      "background-position": ['top center', 'top center'],
      "background-repeat": "no-repeat",
      "background-size": ['100%', 'cover'],
      "min-height": "500px"
    }
  }, [_c('transition', {
    attrs: {
      "appear": ""
    },
    on: {
      "enter": _vm.enter2
    }
  }, [_c('c-box', {
    staticClass: "tableGsap",
    attrs: {
      "width": "100%",
      "max-width": "1100px",
      "pt": ['0', '1rem'],
      "pb": "1rem",
      "px": ['10px', '0'],
      "mx": "auto"
    }
  }, [_c('c-box', {
    attrs: {
      "display": ['block', 'none'],
      "pos": "absolute",
      "top": "-20px",
      "left": "50%",
      "transform": "translate(-50%)",
      "width": "400px",
      "height": "200px",
      "border-radius": "100%",
      "background-image": "linear-gradient(360deg, rgba(244, 204, 70, 0.2) 0%, rgba(244, 204, 70, 0) 86.11%)",
      "z-index": "2"
    }
  }), _c('c-box', {
    attrs: {
      "pos": "relative",
      "display": ['flex'],
      "flex-direction": ['column', 'row'],
      "gap": ['0', '5px'],
      "color": "primary.500",
      "font-size": ['14px', '20px'],
      "font-weight": [500, 700],
      "justify-content": "center",
      "align-items": "center",
      "margin-bottom": "1rem",
      "z-index": "3"
    }
  }, [_c('c-box', {
    attrs: {
      "as": "span"
    }
  }, [_vm._v(" Pilih Layanan Konsultasi ")]), _c('c-box', {
    attrs: {
      "as": "span"
    }
  }, [_vm._v(" yang Paling Cocok untuk Kebutuhanmu ")])], 1), _c('c-box', {
    staticClass: "table-consultation",
    attrs: {
      "pos": "relative",
      "display": ['none', 'table'],
      "as": "table",
      "width": "100%",
      "z-index": "3"
    }
  }, [_c('c-box', {
    attrs: {
      "as": "thead",
      "color": "#FFF",
      "background-color": "primary.400",
      "font-weight": "700",
      "border-radius": "8px"
    }
  }, [_c('c-box', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra",
      value: {
        '>:first-child': {
          borderLeft: '1px solid #005A64'
        },
        '>:last-child': {
          borderRight: '1px solid #005A64'
        },
        '>:not(:last-child):not(:first-child)': {
          borderRight: '1px solid #005A64',
          borderLeft: '1px solid #005A64'
        }
      },
      expression: "{\n                '>:first-child': {\n                  borderLeft: '1px solid #005A64',\n                },\n                '>:last-child': {\n                  borderRight: '1px solid #005A64',\n                },\n                '>:not(:last-child):not(:first-child)': {\n                  borderRight: '1px solid #005A64',\n                  borderLeft: '1px solid #005A64',\n                },\n              }"
    }],
    attrs: {
      "as": "tr"
    }
  }, [_c('c-box', {
    attrs: {
      "as": "th",
      "text-align": "center",
      "font-size": "24px",
      "py": "1rem"
    }
  }, [_vm._v(" Fasilitas Layanan ")]), _vm._l(_vm.programs, function (item) {
    return _c('c-box', {
      key: item.id,
      attrs: {
        "as": "th",
        "flex-direction": "column",
        "justify": "center",
        "py": "1rem",
        "width": "200px"
      }
    }, [_c('c-text', {
      attrs: {
        "font-size": "20px",
        "margin-bottom": "8px"
      }
    }, [_vm._v(" " + _vm._s(item === null || item === void 0 ? void 0 : item.programsService) + " ")]), _c('c-text', {
      attrs: {
        "font-size": "24px"
      }
    }, [_vm._v(" " + _vm._s(_vm.formatCurrency(item === null || item === void 0 ? void 0 : item.price, 2)) + " ")])], 1);
  })], 2)], 1), _c('c-box', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra",
      value: {
        '> tr': {
          borderLeft: '1px solid #C4C4C4',
          borderRight: '1px solid #C4C4C4'
        }
      },
      expression: "{\n              '> tr': {\n                borderLeft: '1px solid #C4C4C4',\n                borderRight: '1px solid #C4C4C4',\n              },\n            }"
    }],
    attrs: {
      "as": "tbody",
      "background-color": "#FFF",
      "color": "#000",
      "font-size": ['12px', '16px']
    }
  }, [_vm._l(_vm.facilities, function (facility, i) {
    return _c('c-box', {
      directives: [{
        name: "chakra",
        rawName: "v-chakra",
        value: {
          '>:not(:last-child):not(:first-child)': {
            borderRight: '1px solid #C4C4C4',
            borderLeft: '1px solid #C4C4C4'
          }
        },
        expression: "{\n                '>:not(:last-child):not(:first-child)': {\n                  borderRight: '1px solid #C4C4C4',\n                  borderLeft: '1px solid #C4C4C4',\n                },\n              }"
      }],
      key: "tr-".concat(i),
      attrs: {
        "as": "tr"
      }
    }, _vm._l(_vm.getTotalProgram + 1, function (k) {
      var _vm$programs, _vm$programs2, _vm$programs3, _vm$programs4;

      return _c('c-box', {
        key: "td-".concat(i, "-").concat(k),
        attrs: {
          "as": "td",
          "px": "1rem",
          "text-align": k === 1 ? 'left' : 'center'
        }
      }, [k === 1 ? _c('c-box', {
        attrs: {
          "w": "100%",
          "pt": i === 0 ? '2rem' : '1rem',
          "pb": "1rem",
          "px": k === 1 ? '12px' : '0',
          "border-bottom": "1px solid #C4C4C4"
        }
      }, [_vm._v(" " + _vm._s(facility === null || facility === void 0 ? void 0 : facility.facility) + " ")]) : _c('c-box', {
        attrs: {
          "w": "100%",
          "pt": i === 0 ? '2rem' : '1rem',
          "pb": "1rem",
          "border-bottom": "1px solid #C4C4C4",
          "display": "flex",
          "justify-content": "center"
        }
      }, [_vm.isCheckList(_vm.getFacilityValue(facility === null || facility === void 0 ? void 0 : facility.facility, (_vm$programs = _vm.programs[k - 2]) === null || _vm$programs === void 0 ? void 0 : _vm$programs.productFacilitiesComparation)) ? _c('inline-svg', {
        staticClass: "icon-list",
        attrs: {
          "src": _vm.checkIcon(_vm.getFacilityValue(facility === null || facility === void 0 ? void 0 : facility.facility, (_vm$programs2 = _vm.programs[k - 2]) === null || _vm$programs2 === void 0 ? void 0 : _vm$programs2.productFacilitiesComparation)) ? _vm.iconCircleCheck : _vm.iconCircleClose,
          "fill": _vm.checkIcon(_vm.getFacilityValue(facility === null || facility === void 0 ? void 0 : facility.facility, (_vm$programs3 = _vm.programs[k - 2]) === null || _vm$programs3 === void 0 ? void 0 : _vm$programs3.productFacilitiesComparation)) ? '#008C81' : '#D32737'
        }
      }) : _c('c-box', [_vm._v(" " + _vm._s(_vm.getFacilityValue(facility === null || facility === void 0 ? void 0 : facility.facility, (_vm$programs4 = _vm.programs[k - 2]) === null || _vm$programs4 === void 0 ? void 0 : _vm$programs4.productFacilitiesComparation)) + " ")])], 1)], 1);
    }), 1);
  }), _c('c-box', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra",
      value: {
        '>:not(:last-child):not(:first-child)': {
          borderRight: '1px solid #C4C4C4',
          borderLeft: '1px solid #C4C4C4'
        }
      },
      expression: "{\n                '>:not(:last-child):not(:first-child)': {\n                  borderRight: '1px solid #C4C4C4',\n                  borderLeft: '1px solid #C4C4C4',\n                },\n              }"
    }],
    attrs: {
      "as": "tr",
      "border-bottom": "1px solid #C4C4C4"
    }
  }, [_c('c-box', {
    attrs: {
      "as": "td"
    }
  }), _vm._l(_vm.programs, function (item) {
    return _c('c-box', {
      key: "buy-".concat(item === null || item === void 0 ? void 0 : item.id),
      attrs: {
        "as": "td",
        "px": "1rem",
        "py": "1rem"
      }
    }, [_c('c-button', {
      directives: [{
        name: "chakra",
        rawName: "v-chakra",
        value: {
          ':hover': {
            color: 'primary.500',
            background: 'secondary.500'
          },
          ':active': {
            color: 'white',
            background: 'secondary.600'
          }
        },
        expression: "{\n                    ':hover': {\n                      color:'primary.500',\n                      background:'secondary.500',\n                    },\n                    ':active': {\n                      color: 'white',\n                      background: 'secondary.600',\n                    },\n                  }"
      }],
      attrs: {
        "width": "100%",
        "variant": "solid",
        "color": "primary.400",
        "background": "secondary.400",
        "border-radius": "60px",
        "height": "50px"
      },
      on: {
        "click": function click($event) {
          $event.preventDefault();
          return _vm.onClickBuy({
            productId: item === null || item === void 0 ? void 0 : item.productId,
            productServiceId: item === null || item === void 0 ? void 0 : item.id
          });
        }
      }
    }, [_vm._v(" Beli Sekarang ")])], 1);
  })], 2)], 2)], 1), _c('c-box', {
    attrs: {
      "pos": "relative",
      "display": ['block', 'none'],
      "z-index": "3",
      "width": "100%"
    }
  }, [_c('c-grid', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra",
      value: {
        '> :not(:last-child):not(:first-child) > div': {
          borderLeft: '1px solid #005A64',
          borderRight: '1px solid #005A64'
        }
      },
      expression: "{\n              '> :not(:last-child):not(:first-child) > div': {\n                borderLeft:'1px solid #005A64',\n                borderRight:'1px solid #005A64',\n              },\n            }"
    }],
    attrs: {
      "w": "100%",
      "template-columns": "repeat(".concat(_vm.getTotalProgram, ", 1fr)"),
      "background-color": "primary.400",
      "color": "#FFF",
      "font-size": "14px",
      "font-weight": "500",
      "border-top-left-radius": "8px",
      "border-top-right-radius": "8px",
      "border": "1px solid #C4C4C4"
    }
  }, _vm._l(_vm.programs, function (item) {
    return _c('c-box', {
      key: item.programsService,
      attrs: {
        "w": "100%",
        "py": "6px"
      }
    }, [_c('c-flex', {
      attrs: {
        "w": "100%",
        "flex-direction": "column",
        "align-items": "center"
      }
    }, [_c('c-text', {
      attrs: {
        "mb": "8px"
      }
    }, [_vm._v(" " + _vm._s(item.programsService) + " ")]), _c('c-text', {
      attrs: {
        "font-size": "16px"
      }
    }, [_vm._v(" " + _vm._s(_vm.formatCurrency(item.price, 2)) + " ")])], 1)], 1);
  }), 1), _c('c-box', {
    attrs: {
      "p": "16px 8px 8px 8px",
      "color": "#000",
      "font-size": "12px",
      "font-weight": "400",
      "background-color": "#FFF",
      "border-bottom-left-radius": "8px",
      "border-bottom-right-radius": "8px",
      "border-left": "1px solid #C4C4C4",
      "border-right": "1px solid #C4C4C4",
      "border-bottom": "1px solid #C4C4C4"
    }
  }, [_vm._l(_vm.facilities, function (facility, index) {
    return _c('c-box', {
      key: "facility-".concat(index),
      attrs: {
        "mb": "8px"
      }
    }, [_c('c-box', {
      attrs: {
        "p": "6px 12px",
        "background-color": "primary.50",
        "text-align": "center"
      }
    }, [_vm._v(" " + _vm._s(facility === null || facility === void 0 ? void 0 : facility.facility) + " ")]), _c('c-grid', {
      directives: [{
        name: "chakra",
        rawName: "v-chakra",
        value: {
          '> :not(:last-child):not(:first-child)': {
            borderLeft: '1px solid #C4C4C4',
            borderRight: '1px solid #C4C4C4'
          }
        },
        expression: "{\n                  '> :not(:last-child):not(:first-child)': {\n                    borderLeft:'1px solid #C4C4C4',\n                    borderRight:'1px solid #C4C4C4',\n                  },\n                }"
      }],
      attrs: {
        "w": "100%",
        "template-columns": "repeat(3, 1fr)"
      }
    }, _vm._l(_vm.getTotalProgram, function (j) {
      var _vm$programs5, _vm$programs6, _vm$programs7, _vm$programs8;

      return _c('c-flex', {
        key: j,
        attrs: {
          "w": "100%",
          "py": "6px",
          "px": "6px",
          "flex-direction": "column",
          "align-items": "center",
          "text-align": "center"
        }
      }, [_vm.isCheckList(_vm.getFacilityValue(facility === null || facility === void 0 ? void 0 : facility.facility, (_vm$programs5 = _vm.programs[j - 1]) === null || _vm$programs5 === void 0 ? void 0 : _vm$programs5.productFacilitiesComparation)) ? _c('inline-svg', {
        staticClass: "icon-list",
        attrs: {
          "src": _vm.checkIcon(_vm.getFacilityValue(facility === null || facility === void 0 ? void 0 : facility.facility, (_vm$programs6 = _vm.programs[j - 1]) === null || _vm$programs6 === void 0 ? void 0 : _vm$programs6.productFacilitiesComparation)) ? _vm.iconCircleCheck : _vm.iconCircleClose,
          "fill": _vm.checkIcon(_vm.getFacilityValue(facility === null || facility === void 0 ? void 0 : facility.facility, (_vm$programs7 = _vm.programs[j - 1]) === null || _vm$programs7 === void 0 ? void 0 : _vm$programs7.productFacilitiesComparation)) ? '#008C81' : '#D32737'
        }
      }) : _c('c-box', [_vm._v(" " + _vm._s(_vm.getFacilityValue(facility === null || facility === void 0 ? void 0 : facility.facility, (_vm$programs8 = _vm.programs[j - 1]) === null || _vm$programs8 === void 0 ? void 0 : _vm$programs8.productFacilitiesComparation)) + " ")])], 1);
    }), 1)], 1);
  }), _c('c-grid', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra",
      value: {
        '> :not(:last-child):not(:first-child)': {
          borderLeft: '1px solid #C4C4C4',
          borderRight: '1px solid #C4C4C4'
        }
      },
      expression: "{\n                '> :not(:last-child):not(:first-child)': {\n                  borderLeft:'1px solid #C4C4C4',\n                  borderRight:'1px solid #C4C4C4',\n                },\n              }"
    }],
    attrs: {
      "w": "100%",
      "template-columns": "repeat(".concat(_vm.getTotalProgram, ", 1fr)")
    }
  }, _vm._l(_vm.programs, function (item) {
    return _c('c-box', {
      key: item.id,
      attrs: {
        "p": "6px 4px"
      }
    }, [_c('c-button', {
      directives: [{
        name: "chakra",
        rawName: "v-chakra",
        value: {
          ':hover': {
            color: 'primary.500',
            background: 'secondary.500'
          },
          ':active': {
            color: 'white',
            background: 'secondary.600'
          }
        },
        expression: "{\n                    ':hover': {\n                      color:'primary.500',\n                      background:'secondary.500',\n                    },\n                    ':active': {\n                      color: 'white',\n                      background: 'secondary.600',\n                    },\n                  }"
      }],
      attrs: {
        "width": "100%",
        "variant": "solid",
        "color": "primary.400",
        "background": "secondary.400",
        "border-radius": "16px",
        "height": "34px",
        "font-size": "12px",
        "px": "0"
      },
      on: {
        "click": function click($event) {
          $event.preventDefault();
          return _vm.onClickBuy({
            productId: item === null || item === void 0 ? void 0 : item.productId,
            productServiceId: item === null || item === void 0 ? void 0 : item.id
          });
        }
      }
    }, [_vm._v(" Beli Sekarang ")])], 1);
  }), 1)], 2)], 1)], 1)], 1), _c('TransitionObserver', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !!((_vm$layananUtama = _vm.layananUtama) !== null && _vm$layananUtama !== void 0 && (_vm$layananUtama$data = _vm$layananUtama.data) !== null && _vm$layananUtama$data !== void 0 && _vm$layananUtama$data.description),
      expression: "!!layananUtama?.data?.description"
    }],
    on: {
      "on-intersect": _vm.enter3
    }
  }, [_c('c-box', {
    staticClass: "layananUtamaGsap",
    attrs: {
      "max-width": "1100px",
      "width": "100%",
      "padding": "0 1rem",
      "mx": "auto"
    }
  }, [_c('c-flex', {
    attrs: {
      "id": "tes",
      "padding": ['12px 30px', '20px'],
      "flex-direction": ['column', 'row'],
      "align-items": "center",
      "box-shadow": "0px 4px 20px 0px rgba(0, 0, 0, 0.15)",
      "border-radius": "10px",
      "border-top-left-radius": "70px",
      "border-bottom-right-radius": "70px",
      "gap": "16px",
      "background-color": "rgba(255, 255, 255, 0.7)"
    }
  }, [_c('c-flex', {
    attrs: {
      "align-items": "center",
      "flex-direction": "column",
      "gap": "16px",
      "width": ['100%', '525px']
    }
  }, [_c('BaseText', {
    attrs: {
      "size-mobile": "16px-2",
      "size-desktop": "20px-2",
      "text-align": "center",
      "color": "#005A64"
    }
  }, [_vm._v(" " + _vm._s((_vm$layananUtama2 = _vm.layananUtama) === null || _vm$layananUtama2 === void 0 ? void 0 : (_vm$layananUtama2$dat = _vm$layananUtama2.data) === null || _vm$layananUtama2$dat === void 0 ? void 0 : _vm$layananUtama2$dat.description) + " ")]), (_vm$layananUtama3 = _vm.layananUtama) !== null && _vm$layananUtama3 !== void 0 && (_vm$layananUtama3$dat = _vm$layananUtama3.data) !== null && _vm$layananUtama3$dat !== void 0 && _vm$layananUtama3$dat.image ? _c('c-image', {
    attrs: {
      "src": (_vm$layananUtama4 = _vm.layananUtama) === null || _vm$layananUtama4 === void 0 ? void 0 : (_vm$layananUtama4$dat = _vm$layananUtama4.data) === null || _vm$layananUtama4$dat === void 0 ? void 0 : _vm$layananUtama4$dat.image,
      "width": ['150px', '65%']
    }
  }) : _vm._e()], 1), _c('c-flex', {
    attrs: {
      "flex-direction": "column",
      "align-items": "center",
      "width": "100%"
    }
  }, [_c('c-flex', {
    attrs: {
      "margin-top": ['0', '16px'],
      "white-space": "nowrap",
      "flex-wrap": "wrap",
      "gap": ['6px', '16px'],
      "justify-content": "center",
      "flex-direction": ['column', 'row'],
      "width": "100%"
    }
  }, _vm._l((_vm$layananUtama5 = _vm.layananUtama) === null || _vm$layananUtama5 === void 0 ? void 0 : (_vm$layananUtama5$dat = _vm$layananUtama5.data) === null || _vm$layananUtama5$dat === void 0 ? void 0 : _vm$layananUtama5$dat.descriptionItems, function (item) {
    return _c('c-flex', {
      key: item,
      attrs: {
        "align-items": "center",
        "justify-content": "space-between",
        "gap": "8px",
        "border-radius": "1000px",
        "padding": "10px 16px",
        "background-color": "white",
        "box-shadow": "0px 4px 20px 0px rgba(0, 0, 0, 0.15)",
        "font-size": ['12px', '16px'],
        "width": ['100%', 'unset'],
        "overflow": "auto"
      }
    }, [_c('p', [_vm._v(" " + _vm._s(item) + " ")]), _c('c-box', {
      attrs: {
        "width": ['18px', '20px'],
        "height": ['18px', '20px'],
        "min-width": ['18px', '20px'],
        "min-height": ['18px', '20px']
      }
    }, [_c('inline-svg', {
      attrs: {
        "src": require('@/assets/icons/icon-circle-check.svg'),
        "height": "100%",
        "width": "100%",
        "fill": "#008C81"
      }
    })], 1)], 1);
  }), 1), _c('BaseButton', {
    attrs: {
      "margin-top": ['12px', '30px'],
      "border-radius": "1000px"
    },
    on: {
      "click": function click($event) {
        var _vm$layananUtama6, _vm$layananUtama6$dat, _vm$layananUtama6$dat2;

        return _vm.$router.push(((_vm$layananUtama6 = _vm.layananUtama) === null || _vm$layananUtama6 === void 0 ? void 0 : (_vm$layananUtama6$dat = _vm$layananUtama6.data) === null || _vm$layananUtama6$dat === void 0 ? void 0 : (_vm$layananUtama6$dat2 = _vm$layananUtama6$dat.button) === null || _vm$layananUtama6$dat2 === void 0 ? void 0 : _vm$layananUtama6$dat2.url) || '#');
      }
    }
  }, [_vm._v(" " + _vm._s(((_vm$layananUtama7 = _vm.layananUtama) === null || _vm$layananUtama7 === void 0 ? void 0 : (_vm$layananUtama7$dat = _vm$layananUtama7.data) === null || _vm$layananUtama7$dat === void 0 ? void 0 : (_vm$layananUtama7$dat2 = _vm$layananUtama7$dat.button) === null || _vm$layananUtama7$dat2 === void 0 ? void 0 : _vm$layananUtama7$dat2.text) || '-') + " ")])], 1)], 1)], 1)], 1), _c('c-box', {
    attrs: {
      "height": "200px"
    }
  })], 1) : _vm._e(), _c('c-box', {
    attrs: {
      "margin-top": "-170px"
    }
  }, [((_vm$listTestimoni = _vm.listTestimoni) === null || _vm$listTestimoni === void 0 ? void 0 : _vm$listTestimoni.length) > 0 ? _c('TransitionObserver', {
    on: {
      "on-intersect": _vm.enter4
    }
  }, [_c('c-box', {
    staticClass: "apaKataMerekaGsap"
  }, [_c('c-box', {
    attrs: {
      "width": "100%",
      "display": "flex",
      "flex-direction": "column",
      "justify-content": "center",
      "padding": ['0', '0 16px']
    }
  }, [_c('BaseText', {
    attrs: {
      "size-mobile": "16px",
      "size-desktop": "28px",
      "margin-bottom": "8px",
      "padding": ['0 16px', '0']
    }
  }, [_vm._v(" Apa Kata Mereka? ")]), _vm.listTestimoni.length > 0 ? _c('VueSlickCarousel', _vm._b({
    directives: [{
      name: "chakra",
      rawName: "v-chakra",
      value: {
        '.slick-slide': {
          padding: '0 16px 8px 0',
          '@media(max-width: 767px)': {
            padding: '0 16px'
          }
        },
        '.slick-dots': {
          bottom: '-25px',
          li: {
            '@media(max-width: 767px)': {
              margin: '0 3px'
            },
            button: {
              '::before': {
                fontSize: '8px',
                color: '#D9D9D9',
                opacity: '1',
                '@media(max-width: 767px)': {
                  fontSize: '10px'
                }
              }
            },
            '&.slick-active': {
              button: {
                '::before': {
                  fontSize: '12px',
                  color: '#008C81',
                  opacity: '1'
                }
              }
            }
          }
        }
      },
      expression: "{\n              '.slick-slide': {\n                padding: '0 16px 8px 0',\n                '@media(max-width: 767px)': {\n                  padding: '0 16px',\n                },\n              },\n              '.slick-dots': {\n                bottom: '-25px',\n                li: {\n                  '@media(max-width: 767px)': {\n                    margin: '0 3px',\n                  },\n                  button: {\n                    '::before': {\n                      fontSize: '8px',\n                      color: '#D9D9D9',\n                      opacity: '1',\n                      '@media(max-width: 767px)': {\n                        fontSize: '10px',\n                      },\n                    },\n                  },\n                  '&.slick-active': {\n                    button: {\n                      '::before': {\n                        fontSize: '12px',\n                        color: '#008C81',\n                        opacity: '1',\n                      },\n                    },\n                  },\n                },\n              },\n            }"
    }]
  }, 'VueSlickCarousel', _vm.settings, false), _vm._l(_vm.listTestimoni, function (item) {
    return _c('c-box', {
      key: item.id
    }, [_c('c-box', {
      attrs: {
        "position": "relative",
        "width": ['100%', '332px'],
        "h": ['auto', '305px'],
        "background-color": "#008C81",
        "border-radius": "12px",
        "padding": "16px",
        "display": "flex",
        "flex-direction": "column",
        "gap": "8px",
        "padding-bottom": "52px",
        "margin-bottom": "39px"
      }
    }, [_c('c-box', {
      attrs: {
        "display": "flex",
        "gap": "16px"
      }
    }, [_c('c-box', {
      attrs: {
        "min-width": "60px",
        "width": "60px",
        "height": "60px"
      }
    }, [item.image ? _c('c-image', {
      attrs: {
        "src": item.image,
        "height": "100%",
        "width": "100%",
        "border-radius": "100%",
        "object-fit": "cover",
        "object-position": "50% 20%"
      }
    }) : _vm._e()], 1), _c('c-box', {
      attrs: {
        "display": "flex",
        "flex-direction": "column",
        "justify-content": "center",
        "width": "100%"
      }
    }, [_c('BaseText', {
      attrs: {
        "size-mobile": "14px-2",
        "size-desktop": "16px",
        "color": "white"
      }
    }, [_vm._v(" " + _vm._s(item.name) + " (" + _vm._s(item.age) + " Tahun) ")]), _c('BaseText', {
      attrs: {
        "size-mobile": "12px",
        "size-desktop": "14px-2",
        "color": "white"
      }
    }, [_vm._v(" " + _vm._s(item.location) + " ")])], 1)], 1), _c('c-box', {
      attrs: {
        "height": "100%"
      }
    }, [_c('BaseText', {
      attrs: {
        "size-mobile": "12px",
        "size-desktop": "14px-2",
        "margin-bottom": "auto",
        "text-align": "justify",
        "color": "white"
      }
    }, [_vm._v(" " + _vm._s(item.description) + " ")])], 1), _c('c-box', {
      attrs: {
        "width": ['190px', '222px'],
        "min-height": ['64px', '67px'],
        "background-color": "white",
        "position": "absolute",
        "bottom": "0",
        "left": "50%",
        "border": "1px solid #008C81",
        "border-radius": "12px",
        "transform": "translate(-50%, 50%)",
        "padding": "5px",
        "display": "flex",
        "flex-direction": "column",
        "justify-content": "center",
        "align-items": "center",
        "gap": "5px"
      }
    }, [_c('BaseText', {
      attrs: {
        "size-mobile": "10px",
        "size-desktop": "12px"
      }
    }, [_vm._v(" Program yang digunakan: " + _vm._s(item.product.name) + " ")]), _c('BaseButton', {
      attrs: {
        "border-radius": "1000px",
        "right-svg-icon": require('@/assets/icons/icon-arrow-right.svg'),
        "right-svg-icon-color": "white",
        "height": "34px"
      },
      on: {
        "click": function click($event) {
          return _vm.goToProgramDetail(item);
        }
      }
    }, [_vm._v(" Lihat layanan ")])], 1)], 1)], 1);
  }), 1) : _vm._e()], 1)], 1)], 1) : _vm._e()], 1), _c('TransitionObserver', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: ((_vm$listOtherProblems = _vm.listOtherProblems) === null || _vm$listOtherProblems === void 0 ? void 0 : (_vm$listOtherProblems2 = _vm$listOtherProblems.data) === null || _vm$listOtherProblems2 === void 0 ? void 0 : _vm$listOtherProblems2.length) > 0,
      expression: "listOtherProblems?.data?.length > 0"
    }],
    on: {
      "on-intersect": _vm.enter5
    }
  }, [_c('c-box', {
    staticClass: "masalahLainnyaGsap",
    attrs: {
      "margin-bottom": "20px",
      "padding": ['42px 0 0 0', '16px 8px 8px 8px']
    }
  }, [_c('BaseText', {
    attrs: {
      "size-mobile": "16px",
      "size-desktop": "28px",
      "text-align": "center"
    }
  }, [_vm._v(" Masalah Lainnya ")]), ((_vm$listOtherProblems3 = _vm.listOtherProblems) === null || _vm$listOtherProblems3 === void 0 ? void 0 : (_vm$listOtherProblems4 = _vm$listOtherProblems3.data) === null || _vm$listOtherProblems4 === void 0 ? void 0 : _vm$listOtherProblems4.length) > 0 ? _c('VueSlickCarousel', _vm._b({
    directives: [{
      name: "chakra",
      rawName: "v-chakra",
      value: {
        '.slick-slide': {
          padding: '8px',
          '@media(max-width: 767px)': {
            padding: '8px 0 16px 16px'
          }
        },
        '.slick-dots': {
          bottom: '-25px',
          li: {
            '@media(max-width: 767px)': {
              margin: '0 3px'
            },
            button: {
              '::before': {
                fontSize: '8px',
                color: '#D9D9D9',
                opacity: '1',
                '@media(max-width: 767px)': {
                  fontSize: '10px'
                }
              }
            },
            '&.slick-active': {
              button: {
                '::before': {
                  fontSize: '12px',
                  color: '#008C81',
                  opacity: '1'
                }
              }
            }
          }
        }
      },
      expression: "{\n          '.slick-slide': {\n            padding: '8px',\n            '@media(max-width: 767px)': {\n              padding: '8px 0 16px 16px',\n            },\n          },\n          '.slick-dots': {\n            bottom: '-25px',\n            li: {\n              '@media(max-width: 767px)': {\n                margin: '0 3px',\n              },\n              button: {\n                '::before': {\n                  fontSize: '8px',\n                  color: '#D9D9D9',\n                  opacity: '1',\n                  '@media(max-width: 767px)': {\n                    fontSize: '10px',\n                  },\n                },\n              },\n              '&.slick-active': {\n                button: {\n                  '::before': {\n                    fontSize: '12px',\n                    color: '#008C81',\n                    opacity: '1',\n                  },\n                },\n              },\n            },\n          },\n        }"
    }],
    class: {
      '--swiping': _vm.swiping === true
    },
    on: {
      "swipe": function swipe($event) {
        return _vm.setCarouselSwiping(true);
      }
    },
    nativeOn: {
      "mouseup": function mouseup($event) {
        return _vm.setCarouselSwiping(false);
      },
      "touchend": function touchend($event) {
        return _vm.setCarouselSwiping(false);
      }
    }
  }, 'VueSlickCarousel', _vm.settings2, false), _vm._l(_vm.listOtherProblems.data, function (item) {
    return _c('c-box', {
      directives: [{
        name: "chakra",
        rawName: "v-chakra",
        value: {
          ':hover': {
            backgroundColor: '#f3f3f3'
          },
          ':active': {
            backgroundColor: '#eaeaea'
          }
        },
        expression: "{\n            ':hover': {\n              backgroundColor: '#f3f3f3'\n            },\n            ':active': {\n              backgroundColor: '#eaeaea'\n            }\n          }"
      }],
      key: item.id,
      attrs: {
        "max-width": ['180px', '230px'],
        "min-height": "250px",
        "as": "div",
        "background-color": "white",
        "box-shadow": ['2px 2px 10px rgba(0, 0, 0, 0.15)'],
        "border-radius": "16px",
        "padding": "16px",
        "display": "flex",
        "flex-direction": "column",
        "gap": "8px",
        "justify-content": "center",
        "align-items": "center"
      },
      on: {
        "click": function click($event) {
          return _vm.goToProgramDetail(item);
        }
      }
    }, [_c('BaseText', {
      attrs: {
        "size-mobile": "18px",
        "size-desktop": "28px",
        "color": ['#008C81'],
        "text-align": "center"
      }
    }, [_vm._v(" " + _vm._s(item.title) + " ")]), _c('c-image', {
      attrs: {
        "src": item.image,
        "min-height": "80px",
        "height": "100%",
        "max-height": ['80px', '100px'],
        "margin": "auto"
      }
    }), _c('BaseText', {
      attrs: {
        "size-mobile": "14px-2",
        "size-desktop": "14px-2",
        "margin-bottom": "auto",
        "text-align": "center"
      }
    }, [_vm._v(" " + _vm._s(item.description) + " ")])], 1);
  }), 1) : _vm._e()], 1)], 1), _c('ModalInfoDifferentProgram', {
    attrs: {
      "is-open": _vm.isOpenModalDifferentProgram
    },
    on: {
      "close": function close($event) {
        _vm.isOpenModalDifferentProgram = false;
      },
      "handle-confirm": function handleConfirm($event) {
        return _vm.checkout({
          productId: _vm.selectedItem
        });
      }
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }