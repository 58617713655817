<template>
  <Observer
    :root-margin="rootMargin"
    :threshold="0.5"
    @on-change="onChange"
  >
    <transition
      appear
    >
      <c-box
        height="100%"
        :opacity="isIntersecting ? 1 : 0"
      >
        <slot />
      </c-box>
    </transition>
  </Observer>
</template>

<script>
import Observer from 'vue-intersection-observer'
import { CBox } from '@chakra-ui/vue'

export default {
  name: 'TransitionObserver',
  components: {
    Observer,
    CBox,
  },
  props: {
    rootMargin: {
      type: String,
      default: '0px',
    },
  },
  emits: ['on-intersect'],
  data() {
    return {
      isIntersecting: false,
    }
  },
  methods: {
    onChange(entry, unobserve) {
      // After loading Cancel monitoring, optimise performance
      if (entry.isIntersecting) {
        this.$emit('on-intersect')
        unobserve()
      }
      this.isIntersecting = entry.isIntersecting
    },

  },
}
</script>
